<template>
  <div class="page-online-upload">
    <div class="banner">
      <img src="../assets/images/caishuipinggu.jpg" alt="" width="100%">
    </div>
    <div class="online">
      <div class="submit-btn">
      </div>
    </div>
    <dynamic-form></dynamic-form>

  </div>
</template>

<script>
import DynamicForm from '@/views/components/dynamicForm'
export default {
  name: 'onlineUpload',
  components: { DynamicForm },
  data () {
    return {
      fileName: ''
    }
  },
  methods: {
  },
  mounted () {
    // this.API.queryTemplateReport().then(({ data }) => {
    //   this.initLuckysheet(JSON.parse(data))
    // })
  }
}
</script>

<style scoped lang="scss">
.page-online-upload {
  background-color: #fff;
  .online {
    margin: 20px;
    position: relative;
    flex-direction: column;
    .submit-btn {
      height: 50px;
      margin: 30px 0;

      .file-input-box {
        height: 40px;
        width: 360px;
        display: inline-block;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        vertical-align: middle;
        line-height: 40px;
        margin-right: 12px;
        position: relative;
        text-align: left;
        .fileName, .file-input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          font-size: 16px;
          line-height: 40px;
        }
        .fileName{
          padding-left: 14px;
        }
        .file-input {
          opacity: 0;
        }
      }
    }
  }
  #luckysheet {
    margin: 0px;
    padding: 0px;
    /* position:absolute;*/
    width: 100%;
    height: 700px;
    flex: 1;
    left: 0px;
    top: 0px;
  }
}
</style>
