<template>
  <div class="step2-index">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane :label="item.name" v-for="(item, index) in yearsList" :key="index">
        <form-detail :rows="item.rows" :groupLength="yearsList.length" :pIndex="index" v-if="yearsList.length" :ref="'detail_'+index" @verifyPass="onVerify"/>
      </el-tab-pane>
    </el-tabs>
    <div class="btn-footer">
      <el-button type="primary" @click="onStepClick(1)">上一步</el-button>
      <el-button type="primary" @click="onStepClick(3)">下一步</el-button>
    </div>
  </div>
</template>

<script>
import FormDetail from './formDetail'
import { mapState } from 'vuex'
export default {
  name: 'index',
  components: { FormDetail },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeName: '0',
      validateArray : {}
    }
  },
  computed: {
    yearsList () {
      return this.data.items
    },
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    onStepClick (num) {
      if (num === 3) {
        let values = Object.values(this.validateArray);
        for (let i=0; i<values.length; i++){
          if (!values[i]) {
            this.$message.error('输入的值非法,请重新检查!')
            return
          }
        }
      }
      const resultObj = {}; const formData = {}
      Object.values(this.$refs).forEach((refItem, index) => {
        Object.assign(resultObj, refItem[0].$data)
      })
      Object.keys(resultObj).forEach(key => {
        if (key.includes('form_')) {
          Object.assign(formData, resultObj[key])
        }
      })
      this.$emit('nextStep', 2, num, formData)
    },
    onVerify (code, isPass) {
      let current = this.validateArray[code]
      if(!current) {
        current = true;
      }
      this.validateArray[code] = current & isPass
      console.log(JSON.stringify(this.validateArray))
    }
  }
}
</script>

<style scope lang="scss" d>
.step2-index{
  .btn-footer{
    margin: 30px auto 0;
  }
}
</style>
