<template>
  <div class="dynamic-form">
    <div class="step-wrap">
<!--      finish-status="success" -->
      <el-steps :active="activeIndex" align-center>
        <el-step title="基本信息" description=""></el-step>
        <el-step title="财税数据" description=""></el-step>
        <el-step title="生成报告" description=""></el-step>
      </el-steps>
    </div>
    <div class="form-group">
      <div class="step step_1" v-show="activeIndex == 1">
        <step1 @nextStep="doNextStep"/>
      </div>

      <div class="step step_2" v-show="activeIndex == 2">
        <step2 :data="result" @nextStep="doNextStep"/>
      </div>
      <div class="step step_3" v-show="activeIndex == 3">
        <step3 @nextStep="doNextStep" />
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import { mapState } from 'vuex'
export default {
  name: 'dynamicForm',
  components: {
    Step1, Step2, Step3
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  data () {
    return {
      activeIndex: 1,
      result: {},
      reportRecordUrl: '',
      params: []
    }
  },
  methods: {
    doNextStep (currentIndex, targetIndex, formData) {
      if (targetIndex === 4) {
        if (!this.userInfo) {
          this.$store.commit('SET_SHOW_LOGIN', true)
          return
        }
        this.saveData(this.params)
      } else {
        if (formData) {
          this.params[currentIndex - 1] = formData
        }
        this.activeIndex = targetIndex || 1
      }
    },
    getDynamicDatas () {
      this.API.getDynamicItem().then(({ code, data }) => {
        this.result = data.items[0]
      })
    },
    saveData (formData) {
      const loading = this.$loading({
        lock: true,
        text: '数据分析中,请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        this.API.postFormData(formData).then(({ data }) => {
          if (data.reportRecordUrl) {
            this.activeIndex = 3
            this.reportRecordUrl = data.reportRecordUrl
            const downUrl = '/v1/download/' + data.reportRecordUrl
            window.open(downUrl)
          } else {
            this.$message.error('文件不存在...')
          }
        }).finally(() => {
          loading.close()
        })
      }, 3000)
    }
  },
  created () {
    this.getDynamicDatas()
  }
}
</script>

<style scoped lang="scss">
.dynamic-form{
  .step-wrap{
    width: 1000px;
    margin: 0 auto;
  }
  .form-group{
    width: 1200px;
    margin: 30px auto 0;
  }
}
</style>
