<template>
  <div class="step1-form-1">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="报告名称">
        <el-input v-model="form.companyName"></el-input>
      </el-form-item>
      <el-form-item label="从业人数(人)" required>
        <el-input v-model="form.personNum"></el-input>
      </el-form-item>
      <el-form-item label="主营行业" required>
        <el-select v-model="form.index" placeholder="请选择主营行业">
          <el-option label="工业" value="0"></el-option>
          <el-option label="农业" value="1"></el-option>
          <el-option label="商业" value="2"></el-option>
          <el-option label="建安" value="3"></el-option>
          <el-option label="房企" value="4"></el-option>
          <el-option label="金融、投资" value="5"></el-option>
          <el-option label="运输" value="6"></el-option>
          <el-option label="物流" value="7"></el-option>
          <el-option label="现代服务" value="8"></el-option>
          <el-option label="传统服务" value="9"></el-option>
          <el-option label="网络软件" value="10"></el-option>
          <el-option label="文旅行业" value="11"></el-option>
          <el-option label="其它" value="35"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小型微利企业">
        <el-radio-group v-model="form.r1">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="一般纳税人">
        <el-radio-group v-model="form.r2">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="高科技企业">
        <el-radio-group v-model="form.r3">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="双软企业">
        <el-radio-group v-model="form.r4">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'form_1',
  data () {
    return {
      form: {
        'companyName': this.createCode()
      }
    }
  },
  methods: {
    createCode () {
      const projectTime = new Date() // 当前中国标准时间
      const Year = projectTime.getFullYear() // 获取当前年份 支持IE和火狐浏览器.
      const Month = projectTime.getMonth() + 1 // 获取中国区月份
      const Day = projectTime.getDate() // 获取几号
      var CurrentDate = Year
      if (Month >= 10) { // 判断月份和几号是否大于10或者小于10
        CurrentDate += Month
      } else {
        CurrentDate += '0' + Month
      }
      if (Day >= 10) {
        CurrentDate += Day
      } else {
        CurrentDate += '0' + Day
      }
      return CurrentDate + Math.floor(Math.random() * 10000)
    }
  }
}
</script>

<style scoped lang="scss">
.step1-form-1{
  width: 50%;
  text-align: left;
}
</style>
