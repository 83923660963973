<template>
  <div class="step1-form-2">
    <el-form ref="form" :model="form" label-position="right">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="甲：" label-width="100px">
            <el-select v-model="form.value1" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate1">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="乙：" label-width="100px">
            <el-select v-model="form.value2" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate2">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="丙：" label-width="100px">
            <el-select v-model="form.value3" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate3">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="丁：" label-width="100px">
            <el-select v-model="form.value4" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate4">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="戊：" label-width="100px">
            <el-select v-model="form.value5" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate5">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="其他股东：" label-width="100px">
            <el-select v-model="form.value6" placeholder="请选择" class="custom-width">
              <el-option label="公司" value="1"></el-option>
              <el-option label="个人" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input v-model="form.rate6">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'form_2',
  data () {
    return {
      form: {}
    }
  }
}
</script>

<style scoped lang="scss">
.step1-form-2  {
  text-align: left;
  padding-left: 50px;
  .custom-width{
    width: 100%;
  }
}
</style>
