<template>
  <div class="step1-index">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="公司信息" name="0"><form1 ref="form1" /></el-tab-pane>
      <el-tab-pane label="公司投资人信息" name="1"><form2 ref="form2" /></el-tab-pane>
      <el-tab-pane label="拟进行交易" name="2"><form3 ref="form3" /></el-tab-pane>
    </el-tabs>
    <div class="btn-footer">
      <el-button type="primary" @click="onStepClick">下一步</el-button>
    </div>
  </div>
</template>

<script>
import Form1 from './form_1'
import Form2 from './form_2'
import Form3 from './form_3'
import { mapState } from 'vuex'
export default {
  name: 'index',
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  components: {
    Form1, Form2, Form3
  },
  data () {
    return {
      activeName: '0'
    }
  },
  methods: {
    onStepClick () {
      const formData = {}
      Object.values(this.$refs).forEach((refItem, index) => {
        const childForm = refItem
        Object.assign(formData, childForm.form)
      })
      this.$emit('nextStep', 1, 2, formData)
    }
  }
}
</script>

<style scoped lang="scss">
.step1-index{
  .btn-footer{
    margin: 30px auto 0;
  }
}
</style>
