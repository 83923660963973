<template>
  <div class="page-form">
    <div class="content">
      <el-row>
        <el-col :span="4">
          <div class="left-item-wrap">
            <div :class="['col-item', {'active': typeId == item.id}]" v-for="(item, index) in rows" :key="index"
              @click="switchType(item)">{{item.name}}</div>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="form-wrap">
            <el-form :ref="modelObj[index]" :model="computedModel(index)" label-width="180px" v-for="(item, index) in rows" :key="index"
              v-show="typeId == item.id">
              <el-row :gutter="60">
                <el-col :span="12" v-for="(sItem, sNum) in item.cell" :key="sNum">
                  <el-form-item :label="sItem.name">
                      <el-input class="custom_input" v-model="computedModel(index)[sItem.code]" @input="validation(computedModel(index),sNum,sItem)" v-if="sItem.unit==0">
                        <template slot="append">元</template>
                      </el-input>
                    <el-select class="custom_select" v-model="computedModel(index)[sItem.code]" placeholder="请选择" v-else>
                      <el-option
                        v-for="item in getOptions(sItem.name)"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <div :class="['tips', {'warn-info': sItem.errorType==1, 'error-info': sItem.errorType==2}]" v-show="sItem.errorType">{{sItem.tips}}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'formDetail',
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    groupLength: Number,
    pIndex: Number
  },
  computed: {
    //

  },
  data () {
    const obj = {}; const modelObj = []
    this.rows.forEach((item, index) => {
      obj[`form_${this.pIndex}_${index}`] = {}
      modelObj[index] = `form_${this.pIndex}_${index}`
    })
    return {
      typeId: this.rows[0].id,
      modelObj,
      options: {
         "tax" : [{
           value: '0',
           label: '0'
         },{
           value: '1',
           label: '1%'
         },{
           value: '0.03',
           label: '3%'
         },{
           value: '0.06',
           label: '6%'
         },{
           value: '0.09',
           label: '9%'
         },{
           value: '0.13',
           label: '13%'
         }],
        "income" : [{
          value: '0',
          label: '0'
        },{
          value: '0.025',
          label: '2.5%'
        },{
          value: '0.15',
          label: '15%'
        },{
          value: '0.2',
          label: '20%'
        },{
          value: '0.25',
          label: '25%'
        }],
        "month" : [{
          value: '1',
          label: '1月'
        },{
          value: '2',
          label: '2月'
        },{
          value: '3',
          label: '3月'
        },{
          value: '4',
          label: '4月'
        },{
          value: '5',
          label: '5月'
        },{
          value: '6',
          label: '6月'
        },{
          value: '7',
          label: '7月'
        },{
          value: '8',
          label: '8月'
        },{
          value: '9',
          label: '9月'
        },{
          value: '10',
          label: '10月'
        },{
          value: '11',
          label: '11月'
        },{
          value: '12',
          label: '12月'
        }]
      },
      ...obj
    }
  },
  methods: {
    switchType (item) {
      this.typeId = item.id
    },
    createForm () {
      this.rows.forEach((item, index) => {
        this[`form_${this.pIndex}_${index}`] = {}
        this.modelObj[index] = `form_${this.pIndex}_${index}`
      })
    },
    computedModel (index) {
      console.log(this[this.modelObj[index]]);
      return this[this.modelObj[index]]
    },
    getOptions (name) {
       if (name.indexOf("增值税税率")>=0)
         return this.options.tax;
       else if (name.indexOf("企业所得税税率")>=0)
         return this.options.income;
       else if (name.indexOf("月份")>=0)
         return this.options.month;
    },
    validation (model, sNum, itme) {
      const code = itme.code
      const value = model[code]
      if (!itme.errorRegex && !itme.warnRegex) {
        return
      }
      if (itme.errorRegex) {
        const regExp = new RegExp(itme.errorRegex)
        const test = regExp.test(value)
        if (test) {
          itme.errorType = 2
          itme.tips = '* ' + itme.errorMessage
          this.$emit('verifyPass', code, false)
          return
        }
      }
      this.$emit('verifyPass', code, true)
      if (itme.warnRegex) {
        const regExp = new RegExp(itme.warnRegex)
        const test = regExp.test(value)
        if (test) {
          itme.errorType = 1
          itme.tips = '* ' + itme.warnMessage
          return
        }
      }
      itme.errorType = 0
      itme.tips = ''
    }
  },
  created () {
    // this.createForm()
  }
}
</script>

<style scoped lang="scss">
.page-form {
  .content{
    .left-item-wrap{
      display: flex;
      align-items: center;
      flex-direction: column;
      .col-item{
        width: 150px;
        background: #ccc;
        color: #fff;
        padding: 7px 10px;
        margin: 10px 0;
        border-radius: 4px;
        cursor: pointer;
        &.active{
          background-color: dodgerblue;
        }
      }
    }
  }
  .form-wrap{
    .tips{
      position: absolute;
      left: 0;
      top: 40px;
      line-height: 20px;
      &.warn-info{
        color: #e6a23c;
      }
      &.error-info{
        color: #df0000;
      }
    }
  }
}
.custom_input ::v-deep .el-input__inner {text-align: right}
.custom_select ::v-deep .el-input__inner {width: 278px}
.el-row{
  display:flex;
  flex-wrap: wrap;
}
</style>
