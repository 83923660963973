<template>
  <div class="step3-index">
    <div class="content">
      <p class="red">请点击“生成报告”，查看贵公司的测评报告！</p>
      <p class="red">感谢使用税易答数字纳税筹划前期测评系统！</p>
      <div class="picture">
        <p>如有相关问题，请扫码咨询</p>
        <img src="https://www.xueshuibao.com/app/static/images/zixun.jpg" alt="">
        <p>人工服务咨询</p>
      </div>
    </div>
    <div class="btn-footer">
      <el-button type="primary" @click="$emit('nextStep', 3, 2)">上一步</el-button>
      <el-button type="primary" @click="$emit('nextStep', 3, 4)">生成报告</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped lang="scss">
.step3-index{
  .content{
    font-size: 14px;
    .red{
      color: red;
      line-height: 32px;
    }
    .picture{
      margin: 50px auto 0;
    }
  }
  .btn-footer{
    margin: 30px auto 0;
  }
}
</style>
