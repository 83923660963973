<template>
  <div class="step1-form-3">
    <el-form ref="form" :model="form" label-width="140px">
      <el-form-item label="股权转让:">
        <el-radio-group v-model="form.r1">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="房地产转让:">
        <el-radio-group v-model="form.r2">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业重组:">
        <el-radio-group v-model="form.r3">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="公司上市:">
        <el-radio-group v-model="form.r4">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="政策性搬迁:">
        <el-radio-group v-model="form.r5">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分红:">
        <el-radio-group v-model="form.r6">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="其他:">
        <el-input v-model="form.desc"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'form_3',
  data () {
    return {
      form: {}
    }
  }
}
</script>

<style scoped lang="scss">
.step1-form-3{
  width: 50%;
  text-align: left;
}
</style>
